.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.active-marker {
  width: 16px;
  height: 16px;
  background-color: #04d182;
  border-radius: 50%;
  opacity: 0.8;
}
.marker {
  width: 16px;
  height: 16px;
  background-color: #72849a;
  border-radius: 50%;
  opacity: 0.8;
}
